@import url('helpers/font/stylesheet.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Gilroy, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

input[type='number'] {
  -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.hide-arrows {
    -moz-appearance: textfield; /* Firefox */
}

body {
  background-color: #F6FDFF;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.react-datepicker {
  border: none !important;
  box-shadow: 0px 15px 24px -4px rgba(16, 24, 40, 0.05), 0px 8px 8px -4px rgba(16, 24, 40, 0.04) !important;
  border: 1px solid #F2F4F7 !important;
}

.react-datepicker__header {
  background-color: #FFF !important;
  border-bottom: none !important;
}

.react-datepicker__current-month {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  color: #344054 !important;
}

.react-datepicker__navigation--next {
  margin-top: 28px !important;
  color: #667085 !important;
}

.react-datepicker__navigation--previous {
  margin-top: 28px !important;
  color: #667085 !important;
}

.react-datepicker__day-name {
  text-transform: capitalize !important;
  font-weight: 500 !important;
  color: #344054 !important;
  width: 40px !important;
  height: 40px !important;

}

.react-datepicker__day {
  font-weight: 500;
  display: flex  !important;
  align-items: center !important;
  justify-content: center !important;
  width: 40px !important;
  height: 40px !important;
  color: #344054 !important;
  border-radius: 100% !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 11px !important;
  padding-right: 11px !important;
}


.react-datepicker__day--in-range {
  color: #FFF !important;
}

.react-datepicker__day--in-selecting-range {
  color: #FFF !important;
}

.react-datepicker__day--outside-month {
  display: none !important;
  background-color: transparent !important;
  color: transparent !important;
  pointer-events: none !important;
  font-weight: 500;
}

.react-datepicker__day--today {
  background-color: #FFF !important;
  color: #344054 !important;
}


.react-datepicker__week {
  margin-left: auto !important;
  display: flex;
  flex-direction: row;
}

.react-datepicker__day--disabled {
  pointer-events: none !important;
  color: #D3D3D3 !important;
}